import React from "react";

export default function CourseOption({ courseInfo, handleCheckbox }) {
    const event = {
        target : { 
            value : courseInfo.id,
            dataset: {
                code: courseInfo.code,
                name: courseInfo.name
            } 
        }
    }

    return (
        <div id="course-option--container">
           <p>{courseInfo.code} - {courseInfo.name}</p>
           <span 
                onClick={() => handleCheckbox(event)}
                className="material-symbols-outlined delete-course-button"
            >
                close
            </span>
        </div>
    )
}