import { useState, useEffect, useCallback } from "react";
import CourseOption from "./CourseOption";
import { SmallSearch } from "../Tutors/SmallSearch";
import { useMediaQuery } from "react-responsive";

export default function CoursesOptions({ setFormData, isSettings, coursesSelected }) {
    const [courses, setCourses] = useState([]);
    const [coursesOptions, setCoursesOptions] = useState([]);
    const [selectedCourses, setSelectedCourses] = useState([]);
    const [coursesIds, setCoursesIds] = useState(new Set());
    const [selectedCoursesElements, setSelectedCoursesElements] = useState([]);
    const [coursesProps, setCoursesProps] = useState([]);

    const isMobile = useMediaQuery({ query: "(max-width: 580px)"});
    
    useEffect(() => {
        setCoursesProps(coursesSelected || []);
    }, [coursesSelected]);

    useEffect(() => {
        setSelectedCourses(coursesProps);
        setCoursesIds(new Set(coursesProps.map((course) => course.id)));
    }, [coursesProps]);

    // Function to delete the object course from newSelectedCourses
    function deleteCourse(arr, currId) {
        return arr.filter(course => course.id !== currId);
    }

    // To deal with the checkboxes
    const handleCheckbox = useCallback((event) => {
        const { value:currId, dataset } = event.target;
        
        setSelectedCourses((prev) => {
            let newSelectedCourses = [ ...prev ];
            
            if (coursesIds.has(Number(currId))) {
                newSelectedCourses = deleteCourse(newSelectedCourses, currId);
                
                setCoursesIds((prevIds) => {
                    const newIds = new Set(prevIds);
                    newIds.delete(Number(currId));
                    return newIds;
                })
            }
            else {
                newSelectedCourses.push({
                    id: Number(currId),
                    name: dataset.name,
                    code: dataset.code
                });

                setCoursesIds((prevIds) => {
                    const newIds = new Set(prevIds);
                    newIds.add(Number(currId));
                    return newIds;
                })
            };

            return newSelectedCourses;
        })
    }, [coursesIds])
    
    // Set up the courses options
    useEffect(() => {
        setCoursesOptions(() => {
            return courses.map((course, idx) => {
                const isChecked = coursesIds.has(Number(course.id));
                
                return (
                    <div key={idx}>
                        <input 
                            id={`courses-checkbox-${idx}`} 
                            type="checkbox" 
                            value={Number(course.id)} 
                            onChange={handleCheckbox} 
                            checked={isChecked} 
                            data-code={course.code}
                            data-name={course.name}
                        />
                        <label htmlFor={`courses-checkbox-${idx}`}>{course.code} - {course.name}</label>
                    </div>
                )
            }) 
        })
    }, [courses, coursesIds, handleCheckbox])

    useEffect(() => {
        setFormData(prev => {
            return {
                ...prev,
                "taughtCourses": Array.from(coursesIds)
            }
        })
    }, [coursesIds, setFormData])
    
    // Create the selected courses
    useEffect(() => {
        setSelectedCoursesElements(() => Array.from(selectedCourses).map((course, key) => {
            return <CourseOption 
                        key={key} 
                        handleCheckbox={handleCheckbox} 
                        courseInfo={course}
                    />
        }))
    }, [handleCheckbox, selectedCourses])

    return (
        <div>
            <label htmlFor="taughtCourses" className="label">Courses:</label>
            <div id="selected-courses--container" className={(isSettings || isMobile) ? "settings-borders settings-white-bg" : ""}>
                {selectedCoursesElements}
            </div>

            <div className={(isSettings || isMobile) ? "settings-white-bg settings-borders" : ""}>
                <SmallSearch 
                    isSettings={isSettings}
                    setCourses={setCourses}
                    isCourses={true}
                    isMobile={isMobile}
                />
                <div id="courses-options" className={(isSettings || isMobile) ? "settings-white-bg" : ""}>
                    {coursesOptions}
                </div>
            </div>
            
        </div>
    )
}