import { useState, useRef, useEffect } from 'react';
import '../../styles/sign-in-up.css';
import { Link } from "react-router-dom";
import { TutorOptions } from '../Tutors/TutorOptions';
import useSignup from '../../hooks/useSignup';
import BackArrow from "../BackArrow";
import validators from "../../helpers/validators";
import { useMediaQuery } from 'react-responsive';
import useDisableNumberInputScroll from '../../hooks/useDisableNumberInputScroll';
import ChooseFileButton from '../ChooseFileButton';
import settingsHelpers from '../../helpers/settingsHelpers';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import useLoaderContext from '../../hooks/useLoaderContext';

export default function SignUpPage() {
    const { signup, error, setError } = useSignup();
    const [areEmptyFieldsValue, setAreEmptyFieldsValue] = useState(false);
    const { cropImage, compressImage } = settingsHelpers;
    const { dispatch:loadingDispatch } = useLoaderContext();

    const [isAgreed, setIsAgreed] = useState(false);

    const cropperRef = useRef(null);

    useDisableNumberInputScroll();

    const { validateForm, isValidPassword } = validators;

    const emptyForm = {
        first_name: "",
        last_name: "",
        email: "",
        username: "",
        password: "",
        confirm_password: "",
        profile: {
            phone_number: ""
        },
        is_tutor: false,
        languages: [],
        description: "", 
        taughtCourses: [],
        rate: ""
    };

    const isMedium = useMediaQuery({ query: '(max-width: 768px)' });
    
    const [formData, setFormData] = useState({ ...emptyForm });
    const [fileUpload, setFileUpload] = useState(null);
    const [fileURL, setFileURL] = useState("");
    const fileRef = useRef(null);

    // References for scrolling when error
    const nameRef = useRef(null);
    const usernameRef = useRef(null);
    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    const phoneRef = useRef(null);

    // Edit the profile picture
    const [isEdit, setIsEdit] = useState(false);

    // Handle form change
    function handleChange(event) {
        setError(null);
        setAreEmptyFieldsValue(false);

        const { name, value } = event.target;

        if (name === "password") {
            const error = {};
            if (value !== "") {
                isValidPassword(value, "", error, false);
            }
            setError(error);
        } 

        if (!formData.is_tutor) {
            setFormData((prev) => {
                return {
                    ...prev,
                    languages: [],
                    description: "", 
                    taughtCourses: [],
                    rate: ""
                }
            })
        }

        if (name === "is_tutor") {
            setFormData((prev) => {
                return {
                    ...prev,
                    [name]: value === "tutor"
                }
            })
        } else if (name === "phone_number") {
            setFormData((prev) => {
                return {
                    ...prev,
                    profile: {
                        ...prev.profile,
                        [name]: value
                    }
                }
            })
        } else {
            setFormData((prev) => {
                return {
                    ...prev,
                    [name]: value 
                }
            })
        }
    }

    // Handle the change of the picture
    async function handleProfileClick(event) {
        loadingDispatch({ type: 'LOAD' });
        const file = event.target.files[0];
        if (file) {
            const finalFile = await compressImage(file);
            setFileUpload(finalFile);
            setFileURL(URL.createObjectURL(new Blob([finalFile], { type: finalFile.type })));
            setIsEdit(true);
        }
        loadingDispatch({ type: 'NO_LOAD' });
    }
    
    // Handle the signing up
    async function handleSignUp(event) {
        event.preventDefault();

        if (!isAgreed) {
            setError({ agreement: "Not agreed to the conditions" });
        } else {
            if (validateForm(formData, setError, setAreEmptyFieldsValue)) {
                // send the sign up request
                await signup(formData, fileUpload, setFormData, emptyForm);
            }
        }
    
    }

    // Handle the clicking of the image
    function handleFileUpload() {
        fileRef.current.click();
    }

    // Scroll to where the errors are
    useEffect(() => {
        if (error) {
            if (error.first_name || error.last_name) nameRef.current.scrollIntoView({ behavior: 'smooth' });
            else if (error.username) usernameRef.current.scrollIntoView({ behavior: 'smooth' });
            else if (error.email) emailRef.current.scrollIntoView({ behavior: 'smooth' });
            else if (error.phone_number || (error.profile && error.profile.phone_number)) phoneRef.current.scrollIntoView({ behavior: 'smooth' });
            else if (error.password || error.confirm_password) passwordRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [error]);

    // If someone wants to cancel adding a profile
    function handleCancelProfile() {
        setIsEdit(false);
    }

    // Handle the cropping of the image
    async function handleCropProfile() {
        const finalFile = await cropImage(cropperRef);
        setIsEdit(false);
        setFileUpload(finalFile);
        setFileURL(URL.createObjectURL(new Blob([finalFile], { type: finalFile.type })));
    }

    // Remove a profile
    function handleRemoveProfile() {
        fileRef.current.value = '';
        setFileURL("");
        setFileUpload("");
    }

    // Handle the agreement checkbox
    function handleAgreement() {
        setError(null);
        setIsAgreed(prev => !prev);
    }

    return (
        <div id="sign-up--container">
            {!isMedium &&
            <BackArrow 
                to="/" 
                additionalClass="absolute"
                />}

            <div className="sign-in-up--background sign-up--bg">
                <div className="contact-section sign-up element">
                    <div className="contact-header">
                        {isMedium && 
                        <BackArrow 
                        to="/" 
                        additionalClass="absolute"
                        />}
                        <h2>Sign Up</h2>
                    </div>
                    <div className="contact-info">
                        <p>Please fill in the form below to sign up as a student or tutor.</p>
                    </div>
                    <form encType="multipart/form-data" accept="*/*" className="form-container"  onSubmit={handleSignUp}>
                        <h1 className="settings-subtitle">Personal Info</h1>
                        <div id="name-sign-up--container">
                            <div className="form-row sign-up--text" id="first-name--sign-up" ref={nameRef}>
                                <label htmlFor="first" className="label">
                                    <span>First Name:</span>
                                    <span className="required-asterix">*</span>
                                </label>
                                <input 
                                    type="text" 
                                    name="first_name" 
                                    placeholder="Enter your first name" 
                                    className={`sign-up--elements${error && error.first_name ? " error-borders" : ""}`} 
                                    onChange={handleChange} 
                                    value={formData.first_name} 
                                />
                                {!areEmptyFieldsValue && error && error.first_name && error.first_name !== "" && <p className="error-p">{error.first_name}</p>}
                            </div>
                            <div className="form-row sign-up--text">
                                <label htmlFor="last" className="label">
                                    <span>Last Name:</span>
                                    <span className="required-asterix">*</span>
                                </label>
                                <input 
                                    type="text" 
                                    name="last_name" 
                                    placeholder="Enter your last name" 
                                    className={`sign-up--elements${error && error.last_name ? " error-borders" : ""}`} 
                                    onChange={handleChange} 
                                    value={formData.last_name} 
                                />
                                {!areEmptyFieldsValue && error && error.last_name && error.last_name !== "" && <p className="error-p">{error.last_name}</p>}
                            </div>
                        </div>
                        <div className="form-row" ref={usernameRef}>
                            <label htmlFor="username" className="label">
                                <span>Username:</span>
                                <span className="required-asterix">*</span>
                            </label>
                            <input 
                                type="text" 
                                id="username" 
                                name="username" 
                                placeholder="Enter your username" 
                                className={`sign-up--elements${error && error.username ? " error-borders" : ""}`}      
                                onChange={handleChange} 
                                value={formData.username} 
                            />
                            {!areEmptyFieldsValue && error && error.username && error.username !== "" && <p className="error-p">{error.username}</p>}
                        </div>
                        <div className="form-row" ref={emailRef}>
                            <label htmlFor="email" className="label">
                                <span>Email:</span>
                                <span className="required-asterix">*</span>
                            </label>
                            <input 
                                type="email" 
                                id="email" 
                                name="email" 
                                placeholder="Enter your email" 
                                className={`sign-up--elements${error && error.email ? " error-borders" : ""}`} 
                                onChange={handleChange} 
                                value={formData.email} 
                            />
                            {!areEmptyFieldsValue && error && error.email && error.email !== "" && <p className="error-p">{error.email}</p>}
                        </div>
                        <div className="form-row" ref={phoneRef}>
                            <label htmlFor="phone_number" className="label">
                                <span>Phone Number:</span>
                            </label>
                            <input 
                                type="number" 
                                id="phone_number" 
                                name="phone_number" 
                                placeholder="Enter your phone number" 
                                className={`sign-up--elements${error && (error.phone_number || (error.profile && error.profile.phone_number)) ? " error-borders" : ""}`} 
                                onChange={handleChange} 
                                value={formData.profile.phone_number} 
                            />
                            {!areEmptyFieldsValue && error && (error.phone_number || (error.profile && error.profile.phone_number)) !== "" && <p className="error-p">{error.phone_number || (error.profile && error.profile.phone_number[0])}</p>}
                            <p className="phone-number-note">By entering your phone number, you agree that it will be displayed in your tutor profile and in students' booking details.</p>
                        </div>

                        <div className="form-row">
                            <label htmlFor="profile_picture" className="label">
                                <span>Profile Picture:</span>
                            </label>
                            <div className="profile-img-signup--container">
                                {fileUpload && !isEdit &&
                                    <div 
                                        className="profile-picture--no-edit-settings" 
                                        onClick={handleFileUpload} 
                                        style={{ backgroundImage: `url(${fileURL})` }}
                                    />
                                }

                                {
                                    isEdit &&
                                    <div className="cropper--container">
                                        <Cropper
                                            src={fileURL}
                                            style={{ height: 200, width: "100%" }}
                                            initialAspectRatio={1 / 1} 
                                            guides={false}
                                            background={false} // Disable the background
                                            responsive={true}
                                            ref={cropperRef}
                                            rotatable={false}
                                            dragMode="move"
                                            cropBoxMovable={false}
                                            cropBoxResizable={false}
                                            minCropBoxWidth={150}
                                            minCropBoxHeight={150}
                                            viewMode={3}
                                            center={false}
                                            highlight={false}
                                        />
                                        <div className="profile-circular-overlay" />
                                    </div>
                                }
                                
                                <ChooseFileButton 
                                    handleProfileClick={handleProfileClick}
                                    fileRef={fileRef} 
                                    fileUpload={fileUpload}
                                />
                                
                                {fileURL && 
                                    <div className="sign-up--profile-button-container">
                                        {isEdit &&
                                            <button
                                                className="edit-profile-settings"
                                                type="button"
                                                onClick={handleCropProfile}
                                            >
                                                Crop
                                            </button>
                                        }
                                        {!isEdit ? 
                                            <button
                                                className="cancel-profile-settings"
                                                onClick={handleRemoveProfile}
                                                type="button"
                                            >
                                                Remove
                                            </button>
                                            :
                                            <button
                                                className="cancel-profile-settings"
                                                type="button"
                                                onClick={handleCancelProfile}
                                            >
                                                Cancel
                                            </button>
                                        }
                                    </div>
                                } 
                            </div>
                        </div>

                        <div className="form-row" ref={passwordRef}>
                            <label htmlFor="password" className="label">
                                <span>Password:</span>
                                <span className="required-asterix">*</span>
                            </label>
                            <input 
                                type="password" 
                                id="password" 
                                name="password" 
                                placeholder="Enter your password" 
                                className={`sign-up--elements${error && error.password ? " error-borders" : ""}`} 
                                onChange={handleChange} 
                                value={formData.password} 
                            />
                            {!areEmptyFieldsValue && error && !error.confirm_password && error.password && error.password !== "" && <p className="error-p">{error.password}</p>}
                        </div>
                        <div className="form-row">
                            <label htmlFor="password" className="label">
                                <span>Confirm Password:</span>
                                <span className="required-asterix">*</span>
                            </label>
                            <input 
                                type="password" 
                                id="confirm_password" 
                                name="confirm_password" 
                                placeholder="Confirm your password" 
                                className={`sign-up--elements${error && error.confirm_password ? " error-borders" : ""}`} 
                                onChange={handleChange} 
                                value={formData.confirm_password} 
                            />
                            {!areEmptyFieldsValue && error && error.confirm_password && error.confirm_password !== "" && <p className="error-p">{error.confirm_password}</p>}
                        </div>
                        <div className="form-row">
                            <label htmlFor="role" className="label">Sign up as:</label>
                            <select id="role" name="is_tutor" className="sign-up--elements" onChange={handleChange} value={formData.is_tutor ? "tutor" : "student"} >
                                <option value="student">Student</option>
                                <option value="tutor">Tutor</option>
                            </select>
                        </div>
                        {formData.is_tutor &&
                        <div>
                            {/* <div className="horizontal-line"></div> */}
                            <hr></hr>
                            <TutorOptions 
                                formData={formData}
                                setFormData={setFormData}
                                handleChange={handleChange}
                                isSettings={false}
                                coursesSelected={[]}
                                error={error}
                                areEmptyFieldsValue={areEmptyFieldsValue}
                            />
                        </div>}
                        
                        <div id="sign-in-up-switch">
                                <p>Have an account?</p>
                                <Link to="/sign-in">Sign in.</Link>
                        </div>
                        <div className="form-row">
                        <div className="agreement--container-sign-up">
                            <input type="checkbox" id="terms-checkbox" checked={isAgreed} onChange={handleAgreement} />
                            <label htmlFor="terms-checkbox" style={error && error.agreement && { color: "var(--red-600)" }}>
                                By checking this box, you agree to our <Link to="/terms-of-services" className="light-green">Terms of Service</Link> and <Link to="/privacy-policy" className="light-green">Privacy Policy</Link>. Please ensure you have read and understood them before proceeding.
                            </label>
                        </div>
                            <button type="submit" className="submit-button">Sign Up</button>
                        </div>
                        {error && !areEmptyFieldsValue && error.length !== 0 && <p style={{ marginTop: "-10px" }} className="error-p">Please fix the errors before proceding.</p>}
                        {areEmptyFieldsValue && <p style={{ marginTop: "-10px" }} className="error-p">{error ? error[Object.keys(error)[0]] : ""}</p>}
                    </form>
                </div>
            </div>
        </div>
    );
};
